<template>
  <div>
      <img class="loginback" src="@/assets/img/login.jpg">
      <div class="login-form">
          <div class="left">
            <el-carousel height="500px">
              <el-carousel-item>
                  <img src="@/assets/img/loginbanner1.jpg"/>
              </el-carousel-item>
              <el-carousel-item>   
                  <img src="@/assets/img/loginbanner2.jpg"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="right">
            <img src="@/assets/img/logo.jpg" style="margin:30px auto 0;display: block;width: 120px;"/>
            <p style="text-align: center;font-size: 26px;">1314军团</p>
              <el-form :model="form" label-width="60px" style="padding:40px">
                <el-form-item label="用户名">
                  <el-input size="large" v-model="FormData.username" />
                </el-form-item>
                <el-form-item label="密码" style="margin-top:20px">
                  <el-input size="large" type="password" v-model="FormData.password" />
                </el-form-item>
                <el-button type="primary" @click="login" style="display: block;width:60%;margin:40px auto;height: 40px;">登录</el-button>
              </el-form>
          </div>
      </div>
  </div>
</template>
<script>
import api from '@/api/index.js'
import { ElMessage } from 'element-plus'
export default {
  components: {
      
  },
  data() {
      return {
        FormData:{
          username:'',
          password:'',
        }
      }
  },
  created() {
      
  },
  computed: {
      
  },
  methods: {
      login(){
        let that = this
        api.aloneApi.login(that.FormData).then(res => {
            if (res.code === 0) {
                localStorage.setItem('userId', res.data.id)
                localStorage.setItem('username', that.FormData.username)
                that.$store.commit('alone/setLoginStatus', true)
                that.$router.push("/");
                ElMessage({
                  message: res.message,
                  type: 'success',
                })
            } else if (res.code == 1002) {
              that.$store.commit('alone/setLoginStatus', true)
                // 当前已经登录
                that.$router.push({path: '/'})
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      }
  }
}
</script>
<style>
.login-form {
  width:1000px;
  height:500px;
  position: absolute;
  left: calc((100% - 1000px)/2);
  top:10%;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}
.loginback{
  width:100%;
  height:100%;
  position: relative;
}
.login-form .left{
  width:500px;
  overflow: hidden;
  float: left;
}
.login-form .left img{width: 100%;}
.login-form .right{
  width:500px;
  overflow: hidden;
  background:#fff;
  height:500px;
}
.login-form .right p{
  font-size: 20px;
  color:#505050;
}
</style>